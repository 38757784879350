export enum StyleConstants {
  WHITE_COLOR = "#ffffff",
  HEADER = "#5e5a66",
  FOOTER = "#5e5a66",
  ACTIVE_SECTION = "#46424f",
  SECTION_BORDER = "#1a1625",
  BLACK_COLOR = "#000000",
  SKILLS_BG = "#2f2b3a",
  EDUCATION_BG = "#2f2b3a",
  NAVIGATION_BG = "#76737e",
  BACKGROUND_COLOR = "#1a1625",

  SPACE_BETWEEN_SECTIONS = 65,

  SIZE_ITEM_TITLE = 18,
}
